<template>
  <div class="sendImage">
    <div
      class="img_boxs"
      :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
      v-if="type === 'one' && imageStr !== ''"
    >
      <div
        :class="{ padding: type === 'one' }"
        class="image_box"
        :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
      >
        <div class="set">
          <span @click="onClose(1)" class="el-icon-delete"></span>
          <strong class="el-icon-search" @click="onView(imageStr)"></strong>
        </div>

        <img
          :src="imageStr"
          :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
          alt=""
        />

        <i></i>
      </div>
    </div>
    <div
      class="img_boxs img_boxs_move"
      :style="{
        width: imageWidth * imageStrs.length + imageStrs.length * 20 + 'px',
      }"
      v-if="type === 'more' && imageStrs"
    >
      <!-- <div draggable   v-for="(item,index) in imageStrs"
        :key="index">
       <img
        
         
          :src="item"
          :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
          alt=""
        />
    </div> -->

      <div
        draggable
        class="image_box"
        :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
        v-for="(item, index) in imageStrs"
        :key="index"
      >
        <div class="set">
          <span @click="onClose(2, item)" class="el-icon-delete"></span>
          <strong class="el-icon-search" @click="onView(item)"></strong>
          <br />
          <!-- <span style="font-size:12px" @click="onSetFirst(item,index)" v-show="index!==0">移到最前</span> -->
        </div>

        <img
          :src="item"
          :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
          alt=""
        />
        <i></i>
      </div>
    </div>
    <div
      class="img_boxs img_boxs_move_pc"
      :style="{
        width: imageWidth * imageStrsPc.length + imageStrsPc.length * 20 + 'px',
      }"
      v-if="type == 'morePc' && imageStrsPc"
    >
      <!-- <div draggable   v-for="(item,index) in imageStrs"
        :key="index">
       <img
        
         
          :src="item"
          :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
          alt=""
        />
    </div> -->
      <div
        draggable
        class="image_box"
        :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
        v-for="(item, index) in imageStrsPc"
        :key="index"
      >
        <div class="set">
          <span @click="onClose(3, item)" class="el-icon-delete"></span>
          <strong class="el-icon-search" @click="onView(item)"></strong>
          <br />
          <!-- <span style="font-size:12px" @click="onSetFirst(item,index)" v-show="index!==0">移到最前</span> -->
        </div>

        <img
          :src="item"
          :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
          alt=""
        />
        <i></i>
      </div>

      <!-- <div

    
        class="image_box"
        :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
        v-for="(item,index) in imageStrs"
        :key="index"
      >
        <div class="set">
          <span @click="onClose(2, item)" class="el-icon-delete"></span>
          <strong class="el-icon-search" @click="onView(item)"></strong>
          <br/>
          <span style="font-size:12px" @click="onSetFirst(item,index)" v-show="index!==0">移到最前</span>
        </div>

        <img
         
          :src="item"
          :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
          alt=""
        />
        <i></i>
      </div> -->
    </div>

    <div
      v-if="imageStr.length < maxLength && type == 'more'"
      class="addImgBtn"
      :style="{ width: btnWidth + 'px', height: btnHeight + 'px' }"
      @click="onAddImage"
    >
      <input @change="onFileChangeMore" type="file" hidden ref="inputFileMore" multiple />

      <i class="el-icon-plus"></i>
    </div>
    <div
      v-if="imageStrsPc.length < maxLength && type == 'morePc'"
      class="addImgBtn"
      :style="{ width: btnWidth + 'px', height: btnHeight + 'px' }"
      @click="onAddImage"
    >
      <input @change="onFileChangeMore" type="file" hidden ref="inputFileMore" multiple />

      <i class="el-icon-plus"></i>
    </div>
    <div
      v-if="!imageStr && type == 'one'"
      class="addImgBtn"
      :style="{ width: btnWidth + 'px', height: btnHeight + 'px' }"
      @click="onAddImage"
    >
      <input @change="onFileChange" type="file" hidden ref="inputFile" />

      <i class="el-icon-plus"></i>
    </div>
    <div class="bg" v-if="viewImage">
      <i class="el-icon-circle-close" @click="onCloseView"></i>
      <img
        :src="viewImage"
        @mousewheel.prevent="rollImg"
        ref="imgDiv"
        alt=""
        class="viewImg"
      />
    </div>
  </div>
</template>
<script>
import { sendFile } from "../api/sendFile";
export default {
  name: "sendImage",
  props: {
    type: {
      //上传图片的数量 'one'-- 一张  'more'--多张
      type: [Number, String],
      default: "one",
    },
    addOrEdit: {
      // 编辑还是添加  1编辑  2添加
      type: [Number, String],
    },
    maxLength: {
      //允许添加的最大图片数量
      type: [Number, String],
      default: 10,
    },
    images: {
      //父组件传的图片,一般编辑的时候用 如果addOrEdit等于1的时候images必传
      type: [String],
    },

    imageWidth: {
      type: [Number, String],
      default: 100,
    },
    imageHeight: {
      type: [Number, String],
      default: 100,
    },
    btnWidth: {
      //添加图片的按钮
      type: [Number, String],
      default: 100,
    },
    btnHeight: {
      type: [Number, String],
      default: 100,
    },
  },
  data() {
    return {
      imageStr: "",
      imageStrs: [],
      imageStrsPc: [],
      viewImage: "",
      nowDom: null,
      listImg: null,
      index: "",
      targetIndex: "",
    };
  },
  watch: {
    imageStr: function () {
      this.$emit("addImageStr", this.imageStr);
    },
    imageStrs: function () {
      console.log("组件值发生修改: ", this.imageStrs);
      this.$emit("addImageStrs", this.imageStrs);
    },
    imageStrsPc: function () {
      console.log("组件值发生修改: ", this.imageStrsPc);
      this.$emit("addImageStrsPc", this.imageStrsPc);
    },
    images(newVal, oldVal) {
      //如果images传了数据过来 编辑 把数据显示
      console.log("组件值发生修改: ", newVal);
      if (this.images) {
        if (this.type === "one") {
          this.imageStr = this.images || "";
          console.log("编辑图片: ", this.imageStr);
        } else if (this.type === "more") {
          this.imageStrs = this.images.split(",");
        } else if (this.type === "morePc") {
          this.imageStrsPc = this.images.split(",");
        }
      } else {
        this.imageStr = "";
        this.imageStrs = [];
        this.imageStrsPc = [];
      }
    },
  },
  created() {
    if (this.images) {
      if (this.type === "one") {
        this.imageStr = this.images || "";
        console.log("编辑图片: ", this.imageStr);
      } else if (this.type === "more") {
        this.imageStrs = this.images.split(",") || "";
      } else if (this.type === "morePc") {
        this.imageStrsPc = this.images.split(",") || "";
      }
    }
  },
  mounted() {
    let className = "";
    if (this.type == "more") {
      className = ".img_boxs_move";
    } else if (this.type == "morePc") {
      className = ".img_boxs_move_pc";
    }
    this.listImg = document.querySelector(className);

    // console.log(this.listImg.children,'listImg')
    //  开始拖动
    this.listImg.ondragstart = (e) => {
      setTimeout(() => {
        e.target.classList.add("moveing");
      }, 0);
      // console.log(e,'----')
      // console.log(Array.from(this.listImg.children),'----')

      this.nowDom = e.target; //当前正在拖动的元素
    };
    this.listImg.ondragover = (e) => {
      e.preventDefault();
    };
    //  拖动中
    this.listImg.ondragenter = (e) => {
      e.preventDefault();

      if (e.target == this.listImg || e.target == this.nowDom) {
        return;
      }

      const children = Array.from(this.listImg.children);

      const index = children.indexOf(this.nowDom);
      const targetIndex = children.indexOf(e.target.parentNode);
      //      this.index2 = index
      // this.targetIndex2 = targetIndex
      //   console.log(e.target.parentNode,'target')
      //   console.log(e,'eeeee')
      //  console.log( this.nowDom,'nowDom---')
      //  console.log(children,'children---')
      //   let tmp = this.imageStrs[index]
      //  this.imageStrs[targetIndex] = tmp
      //   this.imageStrs[index] =  this.imageStrs[targetIndex]
      //   console.log(this.imageStrs)
      console.log(index, targetIndex);
      // console.log(this.index,this.targetIndex)

      if (index < targetIndex) {
        console.log("下");

        this.listImg.insertBefore(this.nowDom, e.target.parentNode.nextElementSibling);
      } else {
        console.log("上");

        this.listImg.insertBefore(this.nowDom, e.target.parentNode);
      }
    };
    // 拖动完成
    this.listImg.ondragend = (e) => {
      e.target.classList.remove("moveing");
      // console.log(this.index)
      // console.log(this.targetIndex)
      // let tmp = this.imageStrs[this.index]
      //  this.imageStrs[this.targetIndex] = tmp
      //   this.imageStrs[this.index] =  this.imageStrs[this.targetIndex]

      if (this.type == "more") {
        console.log(this.imageStrs, "moremore");
        this.imageStrs = Array.from(this.listImg.children).map((item) => {
          return item.childNodes[1].currentSrc;
        });
      } else if (this.type == "morePc") {
        console.log(this.imageStrs, "morePcmorePc");
        this.imageStrsPc = Array.from(this.listImg.children).map((item) => {
          return item.childNodes[1].currentSrc;
        });
      }
    };
  },
  methods: {
    check(e) {
      var num = 0;
      var len = document.myform2.boxc.length;
      for (var i = 0; i < len; i++) {
        if (document.myform2.boxc[i].checked) {
          num++;
        }
      }
      if (num > 2) {
        // document.myform2.boxc[num-1].checked=false;
        alert("最多只能选择三项");
        return false;
      }
    },
    onSetFirst(item, index) {
      this.imageStrs.map((e, i) => {
        if (i == index) {
          console.log(this.imageStrs, "this.imageStrs");
          this.imageStrs.unshift(this.imageStrs.splice(index, 1));
        }
      });
      console.log(this.imageStrs);
    },
    // 缩放图片
    rollImg(event) {
      console.log(123123);
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 80;
      zoom += event.wheelDelta / 12;
      if (zoom >= 80 && zoom < 500) {
        this.$refs.imgDiv.style.zoom = zoom + "%";
      }
      return false;
    },
    //关闭预览图层
    onCloseView() {
      this.viewImage = "";
    },
    //预览图片
    onView(item) {
      this.viewImage = item;
    },
    //删除选中图片
    onClose(type, img) {
      if (type === 1) {
        this.imageStr = "";
      } else if (type === 2) {
        this.imageStrs = this.imageStrs.filter((item) => {
          return item !== img;
        });
      } else if (type == 3) {
        this.imageStrsPc = this.imageStrsPc.filter((item) => {
          return item !== img;
        });
      }
    },
    //选择图片
    onAddImage() {
      if (this.type == "one") {
        this.$refs.inputFile.click();
      } else {
        this.$refs.inputFileMore.click();
      }
    },
    onFileChangeMore(event) {
      console.log(this.imageStrs.length + event.target.files.length);
      if (
        this.imageStrs.length + event.target.files.length > this.maxLength &&
        this.type == "more"
      ) {
        return this.$message.warning(`最多添加${this.maxLength}张图片`);
      } else if (
        this.imageStrsPc.length + event.target.files.length > this.maxLength &&
        this.type == "morePc"
      ) {
        return this.$message.warning(`最多添加${this.maxLength}张图片`);
      }

      let files = event.target.files;
      if (files && files.length > 0) {
        let FilesArr = Object.keys(files).map((item) => {
          return files[item];
        });

        let nFiles = FilesArr.splice(0, this.maxLength - this.imageStrs.length);
        console.log(nFiles, this.maxLength - this.imageStrs.length);

        nFiles.forEach(async (item, index) => {
          var formData = new FormData();
          formData.append("file", item);
          const { data } = await sendFile(formData);
          if (this.type == "more") {
            this.imageStrs.push(data.data[0].src);
          } else if (this.type == "morePc") {
            this.imageStrsPc.push(data.data[0].src);
          }
        });
      }
    },
    async onFileChange(event) {
      // 获取目前上传的文件
      let files = event.target.files;
      let file = files[0];
      let formData = new FormData();
      formData.append("file", file);
      const { data } = await sendFile(formData);
      this.imageStr = data.data[0].src;
      this.$refs.inputFile.value = "";
      // if (files && files.length > 0) {

      //   if(this.type === "one"){

      //   }

      // else if (this.type === "more") {
      //   if (this.imageStrs.length == this.maxLength) {
      //     return this.$message.warning(`最多添加${this.maxLength}张图片`);
      //   }
      //   this.imageStrs.push(data.data[0].src);
      //   this.$refs.inputFile.value = "";
      // }
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.moveing {
  background: transparent;
  color: transparent;
  border: 1px dashed #eee;
  opacity: 0;
}
.sendImage {
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  .addImgBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #c0ccda;
    box-sizing: border-box;
    background: #fff;
    margin-bottom: 20px;

    i {
      font-size: 18px;
    }
  }
  .img_boxs {
    display: flex;
    margin-bottom: 20px;
    max-width: 100%;
    height: 100%;
    flex-wrap: wrap;
    .padding {
      margin-left: unset !important;
    }
    .image_box {
      position: relative;
      margin-left: 20px;

      .set {
        display: none;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100px;
        transform: translate(-50%, -50%);
        z-index: 99;
        span {
          color: #ffff !important;
          font-size: 20px;
          font-weight: 800;
          cursor: pointer;
          margin-left: unset !important;
        }
        strong {
          color: #ffff;
          font-size: 20px;
          font-weight: 800;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &:hover i {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 8;
        background: rgba(0, 0, 0, 0.6);
      }

      &:hover .set {
        display: inline-block;
      }
    }
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;

    .viewImg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .el-icon-circle-close {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 99999;
      font-size: 50px;
      color: #fff !important;
      cursor: pointer;
    }
  }
}
</style>
