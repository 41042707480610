import request from '../utils/request'
import baseUrl from './baseUrl'

export const distributionBaseSelect = (data) => request({
  url: baseUrl + '/distributionBase/selectForBack',
  method: 'POST',
  data
})
export const distributionBaseEdit = (data) => request({
  url: baseUrl + '/distributionBase/modify',
  method: 'POST',
  data
})

export const levelList = (data) => request({
  url: baseUrl + '/distributionLevel/selectForBack',
  method: 'POST',
  data
})
export const addOrEditLevel = (data) => request({
  url: baseUrl + '/distributionLevel/add',
  method: 'POST',
  data
})
export const modifyDistributionLevel = (data) => request({
  url: baseUrl + '/distributionLevel/modify',
  method: 'POST',
  data
})
export const delLevel = (data) => request({
  url: baseUrl + '/distributionLevel/deleteById',
  method: 'POST',
  data
})



export const productListApi = (data) => request({
  url: baseUrl + '/distributionProfit/selectForBack',
  method: 'POST',
  data
})
export const productAddApi = (data) => request({
  url: baseUrl + '/distributionProfit/add',
  method: 'POST',
  data
})
export const productModify = (data) => request({
  url: baseUrl + '/distributionProfit/modify',
  method: 'POST',
  data
})
export const productDeleteApi = (data) => request({
  url: baseUrl + '/distributionProfit/deleteById',
  method: 'POST',
  data
})
export const checkProfit = (data) => request({
  url: baseUrl + '/distributionProfit/selectForId',
  method: 'POST',
  data
})
export const editProfit = (data) => request({
  url: baseUrl + '/distributionProfit/updateById',
  method: 'POST',
  data
})
export const gradeListApi = (data) => request({
  url: baseUrl + '/distributionLevel/selectAll',
  method: 'POST',
  data
})
export const checkRoomList = (data) => request({
  url: baseUrl + '/room/selectForProfit',
  method: 'POST',
  data
})
export const checkTicketList = (data) => request({
  url: baseUrl + '/ticket/selectForProfit',
  method: 'POST',
  data
})
export const checkTravelList = (data) => request({
  url: baseUrl + '/travel/selectForProfit',
  method: 'POST',
  data
})

export const shopMoneyRecord = (data) => request({
  url: baseUrl + '/order/selectProfitForBack',
  method: 'POST',
  data
})
//分销报表下载
export const createForm = (data) => request({
  url: baseUrl + '/order/selectProfitForExport',
  method: 'POST',
  data,
  responseType: "blob",
})
export const isAllShow = (data) => request({
  url: baseUrl + '/distributionProfit/modifyShow',
  method: 'POST',
  data,
  
})
export const levelJournal = (data) => request({
  url: baseUrl + '/levelLog/selectForBack',
  method: 'POST',
  data,
  
})
export const shopJournal = (data) => request({
  url: baseUrl + '/dUserLog/selectForBack',
  method: 'POST',
  data,
  
})
export const goodsJournal = (data) => request({
  url: baseUrl + '/dProfitLog/selectForBack',
  method: 'POST',
  data,
  
})
export const storeFx = (data) => request({
  url: baseUrl + '/storeOrder/selectProfitForBack',
  method: 'POST',
  data,
  
})
export const createFxForm = (data) => request({
  url: baseUrl + '/storeOrder/selectProfitExcel',
  method: 'POST',
  data,
  responseType: "blob",
  
})
export const checkMiaoshalList = (data) => request({
  url: baseUrl + '/seckillInfo/selectForProfit',
  method: 'POST',
  data,

  
})
export const checkPresaleList = (data) => request({
  url: baseUrl + '/presaleInfo/selectForProfit',
  method: 'POST',
  data,

  
})
export const checkPtList = (data) => request({
  url: baseUrl + '/groupBuy/selectForProfit',
  method: 'POST',
  data,

  
})
export const updataSort = (data) => request({
  url: baseUrl + '/distributionProfit/modifyDept',
  method: 'POST',
  data,

  
})
export const barnthSet = (data) => request({
  url: baseUrl + '/distributionProfit/modifyActivity',
  method: 'POST',
  data,

  
})
export const barnthSetShopUser = (data) => request({
  url: baseUrl + '/distributionProfit/modifyShowType',
  method: 'POST',
  data,

  
})
export const taskList = (data) => request({
  url: baseUrl + '/task/selectForBack',
  method: 'POST',
  data,

  
})
export const taskListAdd = (data) => request({
  url: baseUrl + '/task/add',
  method: 'POST',
  data,

  
})
export const taskListUpdata = (data) => request({
  url: baseUrl + '/task/modify',
  method: 'POST',
  data,

})
export const taskListSort = (data) => request({
  url: baseUrl + '/task/modifyDept',
  method: 'POST',
  data,

})
export const taskListShopSort = (data) => request({
  url: baseUrl + '/taskProfit/modify',
  method: 'POST',
  data,

})
export const taskListShopList = (data) => request({
  url: baseUrl + '/taskProfit/selectForBack',
  method: 'POST',
  data,

})
export const taskListShopListAdd = (data) => request({
  url: baseUrl + '/taskProfit/add',
  method: 'POST',
  data,

})
export const taskListShopFxList = (data) => request({
  url: baseUrl + '/distributionProfit/selectForTask',
  method: 'POST',
  data,

})
export const taskListShopListDel = (data) => request({
  url: baseUrl + '/taskProfit/deleteById',
  method: 'POST',
  data,

})
export const disList = (data) => request({
  url: baseUrl + '/menu/selectForBack',
  method: 'POST',
  data,

})
export const disListAdd = (data) => request({
  url: baseUrl + '/menu/add',
  method: 'POST',
  data,

})
export const disListSort = (data) => request({
  url: baseUrl + '/menu/modify',
  method: 'POST',
  data,

})
export const disListDel = (data) => request({
  url: baseUrl + '/menu/deleteById',
  method: 'POST',
  data,

})
export const foodList = (data) => request({
  url: baseUrl + '/food/selectForProfit',
  method: 'POST',
  data,

})
// 分销等级-排序
export const modifiedSort = (data) => request({
  url: baseUrl + '/distributionLevel/modifyDept',
  method: 'POST',
  data,
})
// 查看升级条件
export const getEscalationCondition = (data) => request({
  url: baseUrl + '/levelAsk/select',
  method: 'POST',
  data,
})
// 修改升级条件
export const modifyEscalationCondition = (data) => request({
  url: baseUrl + '/levelAsk/modify',
  method: 'POST',
  data,
})

// 分销-活动列表-查询
export const getDistributionActivityList = (data) => request({
  url: baseUrl + '/levelBase/selectForBack',
  method: 'POST',
  data,
})
// 分销-活动列表-启用/失效
export const updateDistributionActivityStatus = (data) => request({
  url: baseUrl + '/levelBase/update',
  method: 'POST',
  data,
})
// 分销-活动详情-添加/修改
export const addDistributionActivity = (data) => request({
  url: baseUrl + '/levelBase/add',
  method: 'POST',
  data,
})
// 分销-活动详情-添加/修改
export const getDistributionActivityDetail = (data) => request({
  url: baseUrl + '/levelBase/selectForId',
  method: 'POST',
  data,
})

// 分销-升级奖品-列表
export const getUpgradePrizeList = (data) => request({
  url: baseUrl + '/levelGoal/selectForBack',
  method: 'POST',
  data,
})
// 分销-升级奖品-添加/修改奖品
export const addUpgradePrize = (data) => request({
  url: baseUrl + '/levelGoal/add',
  method: 'POST',
  data,
})
// 分销-升级奖品-查看详情奖品
export const getUpgradePrizeDetail = (data) => request({
  url: baseUrl + '/levelGoal/selectForId',
  method: 'POST',
  data,
})
// 分销-升级奖品-删除奖品
export const deletUpgradePrize = (data) => request({
  url: baseUrl + '/levelGoal/deleteById',
  method: 'POST',
  data,
})
// 分销-升级奖品-根据选择的类型和商品id查询名称奖品
export const selectUpgradePrizeName = (data) => request({
  url: baseUrl + '/levelGoal/selectProdName',
  method: 'POST',
  data,
})

// 分销海报列表查询
export const getPosterList= (data) => request({
  url: baseUrl + '/qrCode/selectForBack',
  method: 'POST',
  data,
})
//分销海报禁用/启用
export const updatePosterStatus= (data) => request({
  url: baseUrl + '/qrCode/update',
  method: 'POST',
  data,
})
//分销海报修改排序
export const modifyPosterDept= (data) => request({
  url: baseUrl + '/qrCode/modify',
  method: 'POST',
  data,
})
//分销海报编辑
export const modifyPoster= (data) => request({
  url: baseUrl + '/qrCode/add',
  method: 'POST',
  data,
})