<template>
  <div class="levelList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>等级列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-button type="primary" class="add" @click="onAdd">添加</el-button>
    <el-table style="width: 100%" :data="tableData" border>
      <el-table-column type="selection" align="center" prop="prop" label="label" width="width">
      </el-table-column>
      <el-table-column label="排序"  align="center" width="120">
        <template slot-scope="{ row }">
          <el-input
            v-model="row.dept"
            placeholder="请输入排序值"
            @change="setSort(row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="levelId" label="等级ID">
      </el-table-column>
      <el-table-column align="center" prop="title" label="头衔">
        <template slot-scope="{ row }">
          <el-input class="input-new-tag" v-if="row.titleInputVisible" v-model="row.title" ref="saveTagInput" size="small"
            v-focus="{ cls: 'el-input',tag: 'input', foc: row.titleInputVisible }" @keyup.enter.native="handleInputConfirm1(row, 0)"
            @blur="handleInputConfirm1(row, 0)">
          </el-input>
          <el-tooltip v-else class="item" effect="dark" content="点击修改" placement="top" :enterable="false">
            <el-button class="button-new-tag unset" size="small" @click="onChangeSort1(row)">{{ row.title }}</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="图标" width="120" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.imgUrl"
            :preview-src-list="[scope.row.imgUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" prop="profit" label="升级所需利润">
      </el-table-column> -->
      <el-table-column align="center" prop="firstProfit" label="一级分销分润比例 %">
        <template slot-scope="{ row }">
          <el-input
           :class="{borderRed: !row.firstProfit || row.firstProfit > 100 || row.firstProfit < 0}"
            v-if="row.firstProfitInputVisible"
            v-model="row.firstProfit"
            ref="saveTagInput"
            size="small"
            type="number"
            @input="(e) => tableInput(e, row.index||0, 'firstProfit')"
            @keyup.enter.native="handleInputConfirm2(row, 1)"
            @blur="handleInputConfirm2(row, 1)"
            :step="0.01" :min="0" :max="100"
            v-focus="{ cls: 'el-input',tag: 'input', foc: row.firstProfit }"
          >
          </el-input>
          <el-tooltip v-else class="item" effect="dark" content="点击修改" placement="top" :enterable="false">
            <el-button class="button-new-tag unset" size="small" @click="onChangeSort2(row)">{{ row.firstProfit }}</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="secondProfit" label="二级分销分润比例 %">
        <template slot-scope="{ row }">
          <!-- <el-input class="input-new-tag" v-if="row.secondProfitInputVisible" v-model="row.secondProfit" ref="saveTagInput"
            size="small" @input="onChange" @keyup.enter.native="handleInputConfirm3(row, 2)" @blur="handleInputConfirm3(row, 2)">
          </el-input> -->
          <el-input
           :class="{borderRed: !row.secondProfit || row.secondProfit > 100 || row.secondProfit < 0}"
            v-if="row.secondProfitInputVisible"
            v-model="row.secondProfit"
            ref="saveTagInput"
            size="small"
            type="number"
            @input="(e) => tableInput(e, row.index||0, 'secondProfit')"
            @keyup.enter.native="handleInputConfirm3(row, 2)"
            @blur="handleInputConfirm3(row, 2)"
            :step="0.01" :min="0" :max="100"
            v-focus="{ cls: 'el-input',tag: 'input', foc: row.secondProfit }"
          >
          </el-input>
          <el-tooltip v-else class="item" effect="dark" content="点击修改" placement="top" :enterable="false">
            <el-button class="button-new-tag unset" size="small" @click="onChangeSort3(row)">{{ row.secondProfit }}</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-form-item label="图标">
        <send-image
            type="one"
            :images="row.imgUrl"
            @addImageStr="(e) => addImageStr(e, 1)"
          ></send-image>
        </el-form-item>
        <el-form-item label="">
          <span style="color: red"> 建议尺寸15*15px </span>
        </el-form-item> -->
      <el-table-column align="center" label="是否外部店长"  prop="strOut">
        
      </el-table-column>
      <el-table-column align="center" prop="addTime" label="添加时间">
      </el-table-column>
      <el-table-column align="center" prop="prop" label="操作" width="250px">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="onEdit(row)">编辑</el-button>
          
          <el-dropdown type="primary" style="margin-left: 10px">
            <el-button type="primary" size="mini">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><el-button type="primary" size="mini" v-if="row.levelId !== 1" @click="onDel(row)">删除</el-button></el-dropdown-item>
            <el-dropdown-item><el-button type="primary" size="mini"  @click="toLevelJournal(row)">查看日志</el-button></el-dropdown-item>
            <el-dropdown-item><el-button type="primary" size="mini"  @click="goEscalationCondition(row)"  v-if="
                $store.state.powerList.indexOf('level:ask:list:select') !== -1
              ">升级条件 </el-button></el-dropdown-item>
              <el-dropdown-item><el-button type="primary" size="mini"  @click="$router.push({ path: '/upgradePrizeList', query: { levelId:row.levelId } })"  v-if="
                $store.state.powerList.indexOf('distribution:level:goal:select') !== -1
              ">升级奖品</el-button></el-dropdown-item>
          </el-dropdown-menu>
          </el-dropdown>
          
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]" :page-size="list.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
    <el-dialog destroy-on-close :title="addOrEdit.levelId === 0 ? '添加等级' : '编辑等级'" :visible.sync="addDialogVisible"
      width="45%" @close="onDiaLog">
      <el-form ref="form" label-width="150px">
        <el-form-item label="头衔">
          <el-input placeholder="请输入头衔" v-model="addOrEdit.title"></el-input>
        </el-form-item>
        <el-form-item label="	升级所需利润">
          <el-input-number placeholder="请输入要升级所需利润" v-model="addOrEdit.profit"></el-input-number>
        </el-form-item>
        <el-form-item label="	一级分销分润比例 %">
          <!-- <el-input-number :min="0" :max="100" :step="0.01" :step-strictly="true" placeholder="请输入一级分销分润比例" v-model="addOrEdit.firstProfit" /> -->
          <el-input :class="{borderRed: !addOrEdit.firstProfit || addOrEdit.firstProfit > 100 || addOrEdit.firstProfit < 0}"
            v-model="addOrEdit.firstProfit" type="number" @input="(e) => formInput(e, 'firstProfit')" :step="0.01" :min="0" :max="100" />
          </el-input>
        </el-form-item>
        <el-form-item label="	二级分销分润比例 %">
          <!-- <el-input type="number" :min="0" :max="100" :step="0.01" :step-strictly="true" 
            @input="oninput"
            placeholder="请输入二级分销分润比例" v-model="addOrEdit.secondProfit" /> -->
          <el-input :class="{borderRed: !addOrEdit.secondProfit || addOrEdit.secondProfit > 100 || addOrEdit.secondProfit < 0}" v-model="addOrEdit.secondProfit" type="number" @input="(e) => formInput(e, 'secondProfit')" :step="0.01" :min="0" :max="100" />
          <div style="color: red;">分销分润比例请输入0~100</div>
        </el-form-item>
        <el-form-item label="图标">
        <send-image
            type="one"
            :images="addOrEdit.imgUrl"
            @addImageStr="(e) => addImageStr(e, 1)"
          ></send-image>
        </el-form-item>
        <el-form-item label="">
          <span style="color: red"> 建议尺寸32*32px </span>
        </el-form-item>
        <el-form-item label="是否外部店长">
           <el-radio v-model="addOrEdit.isOut" :label="1">是</el-radio>
            <el-radio v-model="addOrEdit.isOut" :label="0">否</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditCateOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  levelList,
  addOrEditLevel,
  delLevel,
  modifyDistributionLevel,
  modifiedSort,
} from "../../api/wanxiaoShop";
import sendImage from "@/components/sendImage.vue";
export default {
  name: "levelList",
  components: {  sendImage },
  data() {
    return {
      tableData: [],
      levelId: null,
      addOrEdit: {
        title: null,
        profit: null,
        firstProfit: null,
        secondProfit: null,
        isOut: 0,
        imgUrl:''
      },
      addDialogVisible: false,
      list: {
        currentPage: 1,
        pageSize: 5,
      },
      pagination: {},
    };
  },
  created() {
    this.getlevelList();
  },
  directives: {
    focus: {
      inserted: function (el, option) {
        var defClass = "el-input",
          defTag = "input";
        var value = option.value || true;
        if (typeof value === "boolean")
          value = {
            cls: defClass,
            tag: defTag,
            foc: value,
          };
        else
          value = {
            cls: value.cls || defClass,
            tag: value.tag || defTag,
            foc: value.foc || false,
          };
        if (el.classList.contains(value.cls) && value.foc)
          el.getElementsByTagName(value.tag)[0].focus();
      },
    },
  },
  methods: {
    onDel(row) {
      this.$confirm("是否删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delLevel({
            levelId: row.levelId,
          });
          if (data.code === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getlevelList();
          } else {
            this.$message({
              type: "success",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onAdd() {
      this.levelId = 0;
      this.addDialogVisible = true;
    },
    onEdit(row) {
      this.addDialogVisible = true;
      this.levelId = row.levelId;
      this.addOrEdit.title = row.title;
      this.addOrEdit.profit = row.profit;
      this.addOrEdit.firstProfit = row.firstProfit;
      this.addOrEdit.secondProfit = row.secondProfit;
      this.addOrEdit.isOut = row.isOut;
      this.addOrEdit.imgUrl = row.imgUrl;
    },
    //添加编辑确认
    async onEditCateOk() {
      for (const key in this.addOrEdit) {
        if (this.addOrEdit[key] === null || this.addOrEdit[key] === "") {
          return this.$message({
            message: "请把数据填写完整",
            type: "error",
          });
        }
      }
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (
        !reg.test(this.addOrEdit.profit) ||
        !reg.test(this.addOrEdit.firstProfit) ||
        !reg.test(this.addOrEdit.secondProfit)
      ) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
      const { data } = await addOrEditLevel({
        title: this.addOrEdit.title,
        profit: Number(this.addOrEdit.profit),
        firstProfit: Number(this.addOrEdit.firstProfit),
        secondProfit: Number(this.addOrEdit.secondProfit),
        isOut: this.addOrEdit.isOut,
        levelId: this.levelId,
        imgUrl:this.addOrEdit.imgUrl
      });
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.getlevelList();
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
      this.addDialogVisible = false;
    },
    async getlevelList() {
      const { data } = await levelList(this.list);
      if (data.code == 0) {
        data.list.map((item, index) => {
          item.index = index;
          item.titleInputVisible = false;
          item.firstProfitInputVisible = false;
          item.secondProfitInputVisible = false;
        });
        this.tableData = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    onDiaLog() {
      this.addOrEdit.title = null;
      this.addOrEdit.profit = null;
      this.addOrEdit.firstProfit = null;
      this.addOrEdit.secondProfit = null;
      this.addOrEdit.imgUrl = null;
    },
    onChangeSort1(row) {
      row.titleInputVisible = true;
    },
    toLevelJournal(row) {
      this.$router.push("/levelJournal?id=" + row.levelId);
    },
    async handleInputConfirm1(row, type) {
      const { data } = await modifyDistributionLevel({
        levelId: row.levelId,
        type: type,
        title: row.title,
      });
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
      row.titleInputVisible = false;
    },
    onChangeSort2(row) {
      row.firstProfitInputVisible = true;
    },
    async handleInputConfirm2(row, type) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (row.firstProfit < 0 || row.firstProfit > 100 || !row.firstProfit) {
        return this.$message({
          type: "error",
          message: "请输入0~100的数字!",
        });
      }
      const { data } = await modifyDistributionLevel({
        levelId: row.levelId,
        type: type,
        firstProfit: row.firstProfit,
      });
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
      row.firstProfitInputVisible = false;
    },
    onChangeSort3(row) {
      row.secondProfitInputVisible = true;
    },
    async handleInputConfirm3(row, type) {
      // var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      // if (!reg.test(row.secondProfit)) {
      //   return this.$message({
      //     type: "error",
      //     message: "请输入正确的数字!",
      //   });
      // }
      if (row.secondProfit < 0 || row.secondProfit > 100 || !row.secondProfit) {
        return this.$message({
          type: "error",
          message: "请输入0~100的数字!",
        });
      }
      const { data } = await modifyDistributionLevel({
        levelId: row.levelId,
        type: type,
        secondProfit: row.secondProfit,
      });
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
      row.secondProfitInputVisible = false;
    },
    onChange(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
    tableInput(e, index, key) {
      // 通过正则过滤小数点后两位
      console.log(index, "index-key", key);
      this.tableData[index][`${key}`] = e.match(/^\d*(\.?\d{0,2})/g)[0];
      this.tableData = [...this.tableData];
      console.log(this.tableData[index]);
    },
    formInput(e, key) {
      // 通过正则过滤小数点后两位
      console.log("key", key);
      let count = e.match(/^\d*(\.?\d{0,2})/g)[0] || 0;
      // if (e < 0) {
      //   count = 0
      // } else if (e > 100) {
      //   count = 100
      // } else {
      //   count = e.match(/^\d*(\.?\d{0,2})/g)[0] || 0;
      // }
      this.addOrEdit[`${key}`] = count;
      console.log(this.addOrEdit);
    },

    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getlevelList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getlevelList();
    },
    setSort(row) {
      modifiedSort({ levelId: row.levelId, dept: row.dept }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("修改成功");
          this.getlevelList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    goEscalationCondition(row){
      console.log(row.levelId)
      this.$router.push({name:'escalationCondition',query:{levelId:row.levelId}})
    },
    addImageStr(e, type) {
      this.addOrEdit.imgUrl = e;
    },
  },
};
</script>
<style lang="less" scoped>
.levelList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .add {
    margin-top: 20px;
  }

  .el-table {
    margin-top: 50px;
  }

  .unset {
    border: unset !important;
  }

  .el-pagination {
    margin-top: 50px;
    float: right;
  }

  .borderRed {
    // border: 1px solid red;
    // border-radius: 5px;
    /deep/ input {
      border-color: red;
    }
  }
}
</style>
